.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0
}

.pointer-events-none {
  pointer-events: none
}

.fixed {
  position: fixed
}

.absolute {
  position: absolute
}

.relative {
  position: relative
}

.inset-0 {
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px
}

.top-0 {
  top: 0px
}

.right-0 {
  right: 0px
}

.bottom-0 {
  bottom: 0px
}

.z-0 {
  z-index: 0
}

.z-10 {
  z-index: 10
}

.z-40 {
  z-index: 40
}

.col-span-2 {
  grid-column: span 2 / span 2
}

.col-span-4 {
  grid-column: span 4 / span 4
}

.col-span-8 {
  grid-column: span 8 / span 8
}

.float-right {
  float: right
}

.m-2 {
  margin: 0.5rem
}

.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem
}

.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem
}

.mx-auto {
  margin-left: auto;
  margin-right: auto
}

.-mx-2 {
  margin-left: -0.5rem;
  margin-right: -0.5rem
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto
}

.-my-1 {
  margin-top: -0.25rem;
  margin-bottom: -0.25rem
}

.-my-1\.5 {
  margin-top: -0.375rem;
  margin-bottom: -0.375rem
}

.mt-1 {
  margin-top: 0.25rem
}

.mt-2 {
  margin-top: 0.5rem
}

.mt-3 {
  margin-top: 0.75rem
}

.mt-4 {
  margin-top: 1rem
}

.mt-5 {
  margin-top: 1.25rem
}

.mt-6 {
  margin-top: 1.5rem
}

.mt-8 {
  margin-top: 2rem
}

.mr-2 {
  margin-right: 0.5rem
}

.-mr-1 {
  margin-right: -0.25rem
}

.-mr-12 {
  margin-right: -3rem
}

.mb-1 {
  margin-bottom: 0.25rem
}

.mb-2 {
  margin-bottom: 0.5rem
}

.mb-3 {
  margin-bottom: 0.75rem
}

.mb-4 {
  margin-bottom: 1rem
}

.mb-6 {
  margin-bottom: 1.5rem
}

.ml-1 {
  margin-left: 0.25rem
}

.ml-2 {
  margin-left: 0.5rem
}

.ml-3 {
  margin-left: 0.75rem
}

.ml-4 {
  margin-left: 1rem
}

.block {
  display: block
}

.inline-block {
  display: inline-block
}

.flex {
  display: -webkit-flex;
  display: flex
}

.inline-flex {
  display: -webkit-inline-flex;
  display: inline-flex
}

.table {
  display: table
}

.grid {
  display: grid
}

.hidden {
  display: none
}

.h-0 {
  height: 0px
}

.h-2 {
  height: 0.5rem
}

.h-4 {
  height: 1rem
}

.h-5 {
  height: 1.25rem
}

.h-6 {
  height: 1.5rem
}

.h-10 {
  height: 2.5rem
}

.h-16 {
  height: 4rem
}

.h-auto {
  height: auto
}

.h-2\.5 {
  height: 0.625rem
}

.h-full {
  height: 100%
}

.h-screen {
  height: 100vh
}

.min-h-screen {
  min-height: 100vh
}

.w-0 {
  width: 0px
}

.w-2 {
  width: 0.5rem
}

.w-4 {
  width: 1rem
}

.w-5 {
  width: 1.25rem
}

.w-6 {
  width: 1.5rem
}

.w-10 {
  width: 2.5rem
}

.w-14 {
  width: 3.5rem
}

.w-16 {
  width: 4rem
}

.w-40 {
  width: 10rem
}

.w-64 {
  width: 16rem
}

.w-auto {
  width: auto
}

.w-2\.5 {
  width: 0.625rem
}

.w-1\/2 {
  width: 50%
}

.w-full {
  width: 100%
}

.min-w-0 {
  min-width: 0px
}

.min-w-full {
  min-width: 100%
}

.max-w-0 {
  max-width: 0rem
}

.max-w-xs {
  max-width: 20rem
}

.max-w-2xl {
  max-width: 42rem
}

.max-w-3xl {
  max-width: 48rem
}

.max-w-7xl {
  max-width: 80rem
}

.flex-1 {
  -webkit-flex: 1 1 0%;
          flex: 1 1 0%
}

.flex-auto {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto
}

.flex-shrink-0 {
  -webkit-flex-shrink: 0;
          flex-shrink: 0
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg)
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg)
  }
}

@-webkit-keyframes ping {
  75%, 100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0
  }
}

@keyframes ping {
  75%, 100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0
  }
}

@-webkit-keyframes pulse {
  50% {
    opacity: .5
  }
}

@keyframes pulse {
  50% {
    opacity: .5
  }
}

@-webkit-keyframes bounce {
  0%, 100% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1)
  }

  50% {
    -webkit-transform: none;
            transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1)
  }
}

@keyframes bounce {
  0%, 100% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1)
  }

  50% {
    -webkit-transform: none;
            transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1)
  }
}

.cursor-pointer {
  cursor: pointer
}

.list-disc {
  list-style-type: disc
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr))
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr))
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr))
}

.flex-row {
  -webkit-flex-direction: row;
          flex-direction: row
}

.flex-col {
  -webkit-flex-direction: column;
          flex-direction: column
}

.flex-wrap {
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap
}

.content-start {
  -webkit-align-content: flex-start;
          align-content: flex-start
}

.items-center {
  -webkit-align-items: center;
          align-items: center
}

.justify-end {
  -webkit-justify-content: flex-end;
          justify-content: flex-end
}

.justify-center {
  -webkit-justify-content: center;
          justify-content: center
}

.justify-between {
  -webkit-justify-content: space-between;
          justify-content: space-between
}

.gap-6 {
  gap: 1.5rem
}

.gap-x-4 {
  -webkit-column-gap: 1rem;
          column-gap: 1rem
}

.gap-y-6 {
  row-gap: 1.5rem
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.75rem * var(--tw-space-x-reverse));
  margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse))
}

.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse))
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse))
}

.overflow-hidden {
  overflow: hidden
}

.overflow-y-auto {
  overflow-y: auto
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.whitespace-nowrap {
  white-space: nowrap
}

.rounded {
  border-radius: 0.25rem
}

.rounded-md {
  border-radius: 0.375rem
}

.rounded-lg {
  border-radius: 0.5rem
}

.rounded-full {
  border-radius: 9999px
}

.border-2 {
  border-width: 2px
}

.border {
  border-width: 1px
}

.border-t {
  border-top-width: 1px
}

.border-r {
  border-right-width: 1px
}

.border-b {
  border-bottom-width: 1px
}

.bg-green {
  --tw-bg-opacity: 1;
  background-color: rgba(5, 182, 21, var(--tw-bg-opacity))
}

.bg-opacity-75 {
  --tw-bg-opacity: 0.75
}

.object-contain {
  object-fit: contain
}

.p-2 {
  padding: 0.5rem
}

.p-4 {
  padding: 1rem
}

.p-5 {
  padding: 1.25rem
}

.p-6 {
  padding: 1.5rem
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem
}

.pt-2 {
  padding-top: 0.5rem
}

.pt-5 {
  padding-top: 1.25rem
}

.pr-4 {
  padding-right: 1rem
}

.pr-6 {
  padding-right: 1.5rem
}

.pb-0 {
  padding-bottom: 0px
}

.pb-4 {
  padding-bottom: 1rem
}

.pl-3 {
  padding-left: 0.75rem
}

.text-left {
  text-align: left
}

.text-center {
  text-align: center
}

.text-right {
  text-align: right
}

.align-middle {
  vertical-align: middle
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem
}

.font-medium {
  font-weight: 500
}

.font-semibold {
  font-weight: 600
}

.font-bold {
  font-weight: 700
}

.uppercase {
  text-transform: uppercase
}

.leading-5 {
  line-height: 1.25rem
}

.leading-6 {
  line-height: 1.5rem
}

.tracking-wider {
  letter-spacing: 0.05em
}

.text-green {
  --tw-text-opacity: 1;
  color: rgba(5, 182, 21, var(--tw-text-opacity))
}

.underline {
  text-decoration: underline
}

*, ::before, ::after {
  --tw-shadow: 0 0 #0000
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus-within\:outline-none:focus-within {
  outline: 2px solid transparent;
  outline-offset: 2px
}

.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px
}

*, ::before, ::after {
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000
}

.focus-within\:ring-2:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.focus\:ring-inset:focus {
  --tw-ring-inset: inset
}

.focus-within\:ring-offset-2:focus-within {
  --tw-ring-offset-width: 2px
}

.focus\:ring-offset-2:focus {
  --tw-ring-offset-width: 2px
}

.filter {
  --tw-blur: var(--tw-empty,/*!*/ /*!*/);
  --tw-brightness: var(--tw-empty,/*!*/ /*!*/);
  --tw-contrast: var(--tw-empty,/*!*/ /*!*/);
  --tw-grayscale: var(--tw-empty,/*!*/ /*!*/);
  --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
  --tw-invert: var(--tw-empty,/*!*/ /*!*/);
  --tw-saturate: var(--tw-empty,/*!*/ /*!*/);
  --tw-sepia: var(--tw-empty,/*!*/ /*!*/);
  --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/);
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
          filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

@media (min-width: 640px) {
  .sm\:col-span-2 {
    grid-column: span 2 / span 2
  }

  .sm\:col-span-4 {
    grid-column: span 4 / span 4
  }

  .sm\:mx-auto {
    margin-left: auto;
    margin-right: auto
  }

  .sm\:mt-0 {
    margin-top: 0px
  }

  .sm\:ml-4 {
    margin-left: 1rem
  }

  .sm\:block {
    display: block
  }

  .sm\:flex {
    display: -webkit-flex;
    display: flex
  }

  .sm\:grid {
    display: grid
  }

  .sm\:hidden {
    display: none
  }

  .sm\:w-full {
    width: 100%
  }

  .sm\:max-w-md {
    max-width: 28rem
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr))
  }

  .sm\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr))
  }

  .sm\:items-center {
    -webkit-align-items: center;
            align-items: center
  }

  .sm\:justify-start {
    -webkit-justify-content: flex-start;
            justify-content: flex-start
  }

  .sm\:justify-between {
    -webkit-justify-content: space-between;
            justify-content: space-between
  }

  .sm\:gap-4 {
    gap: 1rem
  }

  .sm\:gap-6 {
    gap: 1.5rem
  }

  .sm\:gap-x-8 {
    -webkit-column-gap: 2rem;
            column-gap: 2rem
  }

  .sm\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse))
  }

  .sm\:divide-y > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse))
  }

  .sm\:overflow-hidden {
    overflow: hidden
  }

  .sm\:truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
  }

  .sm\:rounded-md {
    border-radius: 0.375rem
  }

  .sm\:rounded-lg {
    border-radius: 0.5rem
  }

  .sm\:p-0 {
    padding: 0px
  }

  .sm\:p-6 {
    padding: 1.5rem
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem
  }

  .sm\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem
  }

  .sm\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem
  }

  .sm\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem
  }

  .sm\:text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem
  }
}

@media (min-width: 768px) {
}

@media (min-width: 1024px) {
  .lg\:col-span-1 {
    grid-column: span 1 / span 1
  }

  .lg\:col-span-2 {
    grid-column: span 2 / span 2
  }

  .lg\:mx-auto {
    margin-left: auto;
    margin-right: auto
  }

  .lg\:block {
    display: block
  }

  .lg\:flex {
    display: -webkit-flex;
    display: flex
  }

  .lg\:hidden {
    display: none
  }

  .lg\:max-w-4xl {
    max-width: 56rem
  }

  .lg\:flex-shrink-0 {
    -webkit-flex-shrink: 0;
            flex-shrink: 0
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr))
  }

  .lg\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem
  }

  .lg\:py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem
  }

  .lg\:pl-2 {
    padding-left: 0.5rem
  }
}

@media (min-width: 1280px) {
  .xl\:mx-0 {
    margin-left: 0px;
    margin-right: 0px
  }

  .xl\:max-w-none {
    max-width: none
  }

  .xl\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  .xl\:p-12 {
    padding: 3rem
  }
}

@media (min-width: 1536px) {
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

